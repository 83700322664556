@use 'sass:math';

@mixin size($size) {
    width: $size;
    height: $size;
}

@mixin text-contrast($n) {
    $color-brightness: round((red($n) * 299) + (green($n) * 587) + ((blue($n) * 114) / 1000));
    $light-color: round(
        (red(#ffffff) * 299) + (green(#ffffff) * 587) + ((blue(#ffffff) * 114) / 1000)
    );
    $threshold: 1.6;

    @if abs($color-brightness) < ($light-color / $threshold) {
        color: white;
    } @else {
        color: black;
    }
}

@mixin text-with-mode() {
    color: $text-color-light;
    @media (prefers-color-scheme: dark) {
        color: $text-color-dark;
    }
}
