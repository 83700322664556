@import '~@alejosdev/theme-provider/dist/styles/shared.scss';

.root {
    background-color: rgba($color: #fff, $alpha: 0.8);
    border-radius: 20px;
    padding: 1rem 0rem;
    @media (prefers-color-scheme: dark) {
        background-color: rgba(darken($default-bg-dark, 20%), 0.8);
    }
}
