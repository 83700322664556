@import '~@alejosdev/theme-provider/dist/styles/shared.scss';

.content {
    height: 100vh;
    left: 0;
    margin: 0;
    overflow: visible;
    padding: 0.1rem;
    // padding-left: $sidebar-size + 10px;

    padding-top: $status-bar-height;
    position: relative;
    top: 0;
    width: 100vw;

    @include lg {
        padding-left: $sidebar-size + 10px;
        padding-right: 10px;
    }
}
