//
$input-border: #cbcacad5;
$input-height: 32px;

// Generic defaults
$default-bg: #ffffff;
$default-bg-dark: #292929;
$text-color-light: #000;
$text-color-dark: #fff;

$dark-color: #212121;
$light-color: #ffffff;

// Button values
$button-border-color: transparent;
$button-default-radius: 5px;
$button-rounded-radius: 20px;
$button-height: 30px;
$button-bg: $default-bg;

// Colors
$primary: #8d6b94;
$secondary: #b185a7;
$tertiary: #c3a29e;
$quaternary: #e8dbc5;
$quinary: #fff4e9;
$success: #06d6a0;
$error: #ef476f;
$warning: #f2f3ae;
$info: #7796cb;

// Grid
// $grid-max-width: 1440px;
$grid-max-width: 1920px;

// Layout and menu
$sidebar-size: 260px;
$status-bar-height: 70px;

// Titles
$title-1: 1.75rem;
$title-2: 1.5rem;
$title-3: 1.25rem;

$margin-1: 0.25rem;
$margin-2: 0.5rem;
$margin-3: 0.75rem;
$margin-4: 1rem;

$padding-1: 0.25rem;
$padding-2: 0.5rem;
$padding-3: 0.75rem;
$padding-4: 1rem;
