@import '~@alejosdev/theme-provider/dist/styles/shared.scss';

.root {
}

.iconButton {
    background-color: darken($default-bg, 10%);
    padding: 1rem;
    border-radius: 20px;
    margin: 0.5rem;

    @media (prefers-color-scheme: dark) {
        background-color: darken($default-bg-dark, 10%);
    }
    img {
        width: 120px;
    }
    span {
        display: inline-block;
        padding: 0.5rem 1rem;
        margin-top: 0.5rem;
        border-radius: 10px;
        color: $text-color-light;

        @media (prefers-color-scheme: dark) {
            color: $text-color-dark;
        }
    }
}

.iconButtonSelected {
    background-color: rgba($info, 60%);
    span {
        background-color: $info;
        @include text-contrast($info);
    }
}
