@import '~@alejosdev/theme-provider/dist/styles/shared.scss';

.root {
    width: 100%;
    background-color: $default-bg;
    box-shadow: 0px 3px 0px 0px darken($default-bg, 30%);
    margin-bottom: 0.5rem;
    border-radius: 20px;
    padding: 0.5rem;
}

.chart {
    width: 100%;
}

.title {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
}
