@import '~@alejosdev/theme-provider/dist/styles/shared.scss';

.root {
    display: flex;
    align-items: center;
    height: 50px;
    transition: background-color 0.6s;
    cursor: pointer;
    &:hover {
        background-color: rgba($color: $info, $alpha: 0.1);
    }
}

.leftSpace {
    width: 46px;
    height: 20px;
}

.content {
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    &::after {
        top: 0;
        left: 10px;
        z-index: 1;
        content: '';
        position: absolute;
        width: 0.5rem;
        height: 100%;
        background-color: rgba($color: $info, $alpha: 0.6);
    }
}

.icon {
    z-index: 2;
}
