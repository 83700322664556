@import '~@alejosdev/theme-provider/dist/styles/shared.scss';

.actionButton {
    @include lg {
        display: none;
    }
}

.header {
    display: flex;
    padding: 1rem 0.5rem;
    // width: $sidebar-size;
    // height: 100%;
    align-items: center;

    .titleContent {
        padding-left: 0.5rem;
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: flex-start;
        justify-content: center;
    }

    h2,
    p {
        color: $text-color-light;
        margin: 0;
        @media (prefers-color-scheme: dark) {
            color: $text-color-dark;
        }
    }
    p {
        font-size: 0.8rem;
    }
}

.logoWrapper {
    height: 100%;

    img {
        height: $status-bar-height - 30px;
    }

    @include lg {
        margin-right: 0.5rem;
    }
}
