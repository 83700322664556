@import '~@alejosdev/theme-provider/dist/styles/shared.scss';

.root {
    position: fixed;
    bottom: 1rem;
    width: 500px;
    max-width: 100%;

    backdrop-filter: blur(4px);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    background-color: rgba($color: #fff, $alpha: 0.8);
    padding: 1rem 2rem;
    border-radius: 30px;
    z-index: 2000;

    @media (prefers-color-scheme: dark) {
        background-color: rgba(darken($default-bg-dark, 20%), 0.8);
    }

    @include md {
        right: 1rem;
    }
}

.windowControls {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.header {
    display: flex;
    justify-content: center;

    .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--icon-bg-color);
        border-radius: 50%;
        margin-right: 0.5rem;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
    }

    p,
    span {
        color: $text-color-light;
        @media (prefers-color-scheme: dark) {
            color: $text-color-dark;
        }
    }
}

.minimized {
    display: flex;
    flex-direction: column;
    padding-right: 3rem;

    .header {
        justify-content: flex-start;
        flex: 1;
    }
}
