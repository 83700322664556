@import '~@alejosdev/theme-provider/dist/styles/shared.scss';

.root {
    display: flex;
    justify-content: center;
    align-items: center;
}

.number,
.separator {
    color: $text-color-light;
    @media (prefers-color-scheme: dark) {
        color: $text-color-dark;
    }
}

.number {
    font-size: 1.5rem;
    margin: 0 0.25rem;
}

.progress {
    width: 100%;
    height: 10px;
    background-color: rgba($color: #000000, $alpha: 0.3);
    border-radius: 5px;
    margin-bottom: 0.5rem;
    overflow: hidden;
}

.progressBar {
    height: 100%;
    border-radius: 5px;
    background-color: $primary;
    transition: width 1s;
}

.label,
.value {
    font-size: 0.8rem;
    color: $text-color-light;

    @media (prefers-color-scheme: dark) {
        color: $text-color-dark;
    }
}

.label {
    margin-right: 0.5rem;
}
