@import '~@alejosdev/theme-provider/dist/styles/shared.scss';
.backdrop {
    background-color: transparent;
    // backdrop-filter: blur(4px);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1999;
    animation: menuBackdropAnimation 1s ease forwards;
    @include lg {
        display: none;
    }
}

@keyframes menuBackdropAnimation {
    0% {
        backdrop-filter: blur(0px);
    }
    100% {
        backdrop-filter: blur(4px);
    }
}

@keyframes menuEnterAnimation {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.root {
    background-color: rgba($color: #fff, $alpha: 0.8);
    position: fixed;
    backdrop-filter: blur(10px);
    bottom: 0;
    top: 0;
    width: 300px;
    max-width: 90%;
    // bottom: 0;
    // position: absolute;
    z-index: 2000;
    animation: menuEnterAnimation 0.6s ease-out forwards;

    // width: $sidebar-size;
    // overflow-y: auto;
    // max-height: 100dvh;
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
    @media (prefers-color-scheme: dark) {
        background-color: rgba(darken($default-bg-dark, 20%), 0.8);
    }

    @include lg {
        animation: none;
        transform: translateX(0);
        width: $sidebar-size;
        top: $status-bar-height;
    }
}

.appHeaderMobile {
    &.appHeaderMobile {
        @include lg {
            display: none;
        }
    }
}

.mobileHidden {
    display: none;
    @include lg {
        display: inherit;
    }
}

.menu {
    margin: 0;
    padding: 0.5rem;

    li .navLink {
        align-items: center;
        cursor: pointer;
        border-radius: 20px;
        display: flex;
        list-style-type: none;
        margin-bottom: 0.5rem;
        width: 100%;
        transition: background-color 0.6s;
        text-decoration: none;
        &:hover,
        &.active {
            background-color: darken(#fff, 10%);
            @media (prefers-color-scheme: dark) {
                background-color: darken($default-bg-dark, 10%);
            }
        }
        z-index: 1;
    }
    li span {
        color: $text-color-light;
        flex: 1;
        @media (prefers-color-scheme: dark) {
            color: $text-color-dark;
        }
    }
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
}
