@import '~@alejosdev/theme-provider/dist/styles/shared.scss';
.appHeaderContent {
    &.appHeaderContent {
        display: none;
        @include lg {
            display: inherit;
        }
    }
}
.appHeader {
    // display: none;
    @include lg {
        // display: inherit;
    }
}

.mobileActionButton {
    margin-left: 1rem;
    @include lg {
        display: none;
    }
}

.root {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1001;
    width: 100dvw;
    backdrop-filter: blur(4px);
    // box-shadow: 0px 0px 16px 1px rgba(255, 255, 255, 0.5);

    height: $status-bar-height;
    background-color: rgba($color: #fff, $alpha: 0.8);
    // border-bottom: 1px solid darken($color: #fff, $amount: 10%);

    @media (prefers-color-scheme: dark) {
        background-color: rgba(darken($default-bg-dark, 20%), 0.8);
        // box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.5);
        // border-bottom: 1px solid darken($color: #fff, $amount: 80%);
    }
}

.filterWrapper {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;

    @include sm {
        padding: 0rem 2rem;
    }
}

.searchboxInput {
    flex-basis: 500px;
}
.action {
    margin-right: 0.4rem;
}

.actionsWrapper {
    // Mobile
    display: none;
    // Desktop
    padding-right: 0.5rem;
}

.actionsMobile {
}
