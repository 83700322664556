@import '~@alejosdev/theme-provider/dist/styles/shared.scss';

.root {
    color: $text-color-light;
    display: flex;
    position: relative;
    cursor: pointer;
    padding: 0rem 0.4rem;

    &:hover {
        background-color: darken($default-bg, 20%);
    }

    @media (prefers-color-scheme: dark) {
        color: $text-color-dark;
        &:hover {
            background-color: lighten($default-bg-dark, 0.5%);
        }
    }
}

.iconWrapper {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--icon-color);
    z-index: 2;
}

.content {
    flex: 1;
    position: relative;
    &::after {
        top: 0;
        left: -15px;
        z-index: 1;
        transform: translateX(-50%);
        content: '';
        position: absolute;
        width: 0.5rem;
        height: 100%;
        background-color: var(--icon-color);
    }
}

.timeShow {
    // position: absolute;
    // top: 40px;
    margin-top: 0.5rem;
    width: 30px;
    margin-right: 0.5rem;
    span {
        display: block;
        font-size: 0.6rem;
    }
}

.startedDate,
.endDate {
    width: 30px;
    margin-bottom: 0.2rem;
    border-radius: 20px;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
    font-size: 0.6rem;
}

.contentWrapper {
    padding: 0.4rem 1rem;
    border-bottom: 1px solid rgba(#fff, 20%);
}

.elapsedTimeWrapper {
    display: flex;
    align-items: center;
    span {
        font-size: 0.8rem;
        margin-left: 0.2rem;
    }
}

.activityInfoWrapper {
    margin-bottom: 0.5rem;
}
