@import '~@alejosdev/theme-provider/dist/styles/shared.scss';

.react-select-control____single-value {
    &.react-select-control____single-value {
        color: $text-color-light;
        @media (prefers-color-scheme: dark) {
            color: $text-color-dark;
        }
    }
}
