@import '~@alejosdev/theme-provider/dist/styles/shared.scss';

.root {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.3rem;
    border-bottom: 1px solid rgba($default-bg, 50%);
}

.control {
    margin-right: 0.5rem;
}

.activityName {
    color: $text-color-light;

    @media (prefers-color-scheme: dark) {
        color: $text-color-dark;
    }
}
